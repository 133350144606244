<template>
    <div>
        <el-card shadow="hover">
            <div slot="header" class="clearfix">
                <span style="font-weight: bold;font-size: 20px;">{{$t('menu_name.out_product')}}</span>
                <el-date-picker
                        style="width: 35%;margin-left:1%;margin-right:1%;"
                        v-model="value2"
                        type="daterange"
                        align="right"
                        unlink-panels
                        :range-separator="$t('quick.to')"
                        :start-placeholder="$t('quick.start_placeholder')"
                        :end-placeholder="$t('quick.end_placeholder')"
                        :picker-options="pickerOptions"
                        :format="$lang == 2 ? 'yyyy 年 MM 月 dd 日' : 'yyyy يىلى MM ئاينىڭ dd كۈنى'"
                        value-format="yyyy-MM-dd H:m:s"
                        @change="dateTime">
                </el-date-picker>
            </div>
            <Row :gutter="10">
                <Col :span="24" v-if="canInGoods">
                    <el-button icon="el-icon-upload2" @click="productCreate">{{$t('customer_table.out_insert_product')}}</el-button>
                    <el-divider direction="vertical"></el-divider>

                    <!--此处简介查询-->
                    <!--                    <el-button-group>-->
                    <!--                        <el-button @click="stateSreach(0)">{{$t('customer_table.wait_for_review')}}</el-button>-->
                    <!--                        <el-button @click="stateSreach(1)">{{$t('customer_table.checked')}}</el-button>-->
                    <!--                        <el-button @click="stateSreach(2)">{{$t('customer_table.rejected')}}</el-button>-->
                    <!--                        <el-button @click="stateSreach(3)">{{$t('customer_table.det')}}</el-button>-->
                    <!--                        <el-button @click="stateSreach(3)">{{$t('customer_table.det')}}</el-button>-->
                    <!--                    </el-button-group>-->

                    <el-divider></el-divider>
                </Col>
                <Col :span="24" v-else>
                    <el-button type="primary" @click="selectButton(selectButtonType = 1)">{{$t('customer_table.normal_product')}}</el-button>
                    <el-divider direction="vertical"></el-divider>

                    <el-button type="primary" @click="selectButton(selectButtonType = 2)">{{$t('customer_table.sub_warehouse_product')}}</el-button>
                    <el-divider direction="vertical"></el-divider>

                    <el-button type="primary" @click="selectButton(selectButtonType = 3)">{{$t('customer_table.wechat_mini_product')}}</el-button>
                    <el-divider direction="vertical"></el-divider>
                </Col>
                <Col :span="24">
                    <div style="text-align: center">

                        <el-table
                                :data="tableData"
                                :header-cell-style="{'text-align':'center'}"
                                :cell-style="{'text-align':'center'}"
                                max-height="600"
                                style="width: 100%"
                                v-loading="tableLoading">
                            <el-table-column
                                    prop="id"
                                    type="index"
                                    label="ID">
                            </el-table-column>
                            <el-table-column
                                    prop="number"
                                    :label="$t('customer_table.number_no')">
                            </el-table-column>
                            <el-table-column
                                    prop="warehouse_name"
                                    :label="$t('customer_table.warehouse_name')">
                            </el-table-column>
                            <el-table-column
                                    prop="crated_at"
                                    :label="$t('customer_table.time')">
                            </el-table-column>
                            <el-table-column
                                    prop="money"
                                    :label="$t('customer_table.total_money')">
                                <template slot-scope="scope">
                                    <el-tag type="danger">{{scope.row.money}}</el-tag>
                                </template>
                            </el-table-column>
                            <el-table-column
                                    prop="count_name"
                                    :label="$t('customer_table.case_in_num')">
                            </el-table-column>
                            <el-table-column
                                    prop="state_name"
                                    :label="$t('customer_table.state')">
                                <template slot-scope="scope">
                                    <el-tag type="info"    v-if="scope.row.state == 0">{{scope.row.state_name}}</el-tag>
                                    <el-tag type="success" v-if="scope.row.state == 1">{{scope.row.state_name}}</el-tag>
                                    <el-tag type="warning" v-if="scope.row.state == 2">{{scope.row.state_name}}</el-tag>
                                </template>
                            </el-table-column>
                            <el-table-column
                                    :label="$t('customer_table.operation')"
                                    width="250">
                                <template slot-scope="scope">
                                    <Row style="text-align: center;justify-items: center;margin: 0 auto">
                                        <Col :span="24">
                                            <el-button icon="el-icon-view" @click="handleShow(scope.row)" type="primary" size="small">
                                                {{$t("system_show")}}
                                            </el-button>
                                        </Col>
                                    </Row>
                                </template>
                            </el-table-column>
                        </el-table>
                        <div style="text-align: center;margin-top: 30px;">
                            <el-pagination
                                    background
                                    layout="prev, pager, next"
                                    :total="total"
                                    @current-change="current_change">
                            </el-pagination>
                        </div>
                    </div>

                </Col>
            </Row>
        </el-card>

        <el-dialog
                :title="$t('system_shows')"
                :visible.sync="dialogVisible"
                width="80%"
                center
                style="height: 90%"
                :close-on-click-modal="false"
                :before-close="handleClose">
            <Row :gutter="5">
                <Col :span="11">
                    <Row :gutter="10" v-for="(item,index) in detail.headers" :key="index">
                        <Col :span="12" style="float: left">
                            {{item.key}}
                        </Col>
                        <Col :span="12" style="float: right">
                            {{item.value}}
                        </Col>
                        <Divider dashed />
                    </Row>
                    <Spin fix v-if="showSpin"></Spin>
                    <div>
                        <el-image v-for="(item,index) in detail.images"
                                  :src="item.img"
                                  :preview-src-list="itemsShowImage"
                                  :key="index"
                                  style="width: 100px;height: 100px;padding: 5px;">
                            <div slot="error" class="image-slot">
                                <i class="el-icon-picture-outline"></i>
                            </div>
                        </el-image>
                        <Spin fix v-if="showSpin"></Spin>
                    </div>
                </Col>
                <Col :span="1">
                    <el-divider class="dialog-hr" direction="vertical"></el-divider>
                </Col>
                <Col :span="12">
                    <Row :gutter="2" v-for="(item,index) in detail.products" :key="index" class="products_detail">
                        <Col :span="4">
                            <el-image
                                    :src="item.image"
                                    style="width: 100px;height: 100px;padding: 5px; ">
                                <div slot="error" class="image-slot">
                                    <i class="el-icon-picture-outline"></i>
                                </div>
                            </el-image>
                            <Spin fix v-if="showSpin"></Spin>
                        </Col>
                        <Col :span="20">
                            <div style="color: #1c6fff">
                                {{item.name}}
                            </div>
                            <div>
                                {{$t('customer_table.product_no')}}:{{item.product_no}}
                            </div>
                            <div>
                                {{$t('customer_table.case_in_num')}}:<span style="color: #1c6fff">{{item.count_name}}</span>
                            </div>
                            <div>
                                {{$t('customer_table.product_date')}}:{{item.product_date}}
                            </div>
                            <div>
                                {{$t('customer_table.money_dan')}}: <span style="color: #f50f0f">{{item.money}}</span>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                {{$t('customer_table.total_money')}}:<span style="color: #f50f0f">{{item.total_money}}</span>
                            </div>
                        </Col>
                        <Spin fix v-if="showSpin"></Spin>
                        <Divider dashed />
                    </Row>
                </Col>
            </Row>
            <div slot="footer" class="dialog-footer" style="text-align: center;" v-if="!canInGoods && detail.state !== 1 && detail.state !== 2">
                <template v-if="supplier_id == 3">
                    <el-button type="primary" style="width: 15%" @click="miniCreate" :loading="submitLoading">{{$t('in_mini_submit')}}</el-button>
                </template>
                <template v-else>
                    <el-button @click="remont" type="danger" style="margin-left: 2%;width: 15%" :loading="submitLoading">{{$t('bohui_submit')}}</el-button>
                    <el-button type="primary" style="width: 15%" @click="customerCreate" :loading="submitLoading">{{$t('shenhe_submit')}}</el-button>
                    <el-button @click="printer_click" type="primary" plain style="margin-left: 2%;width: 15%" :loading="submitLoading">{{$t('print_submit')}}</el-button>
                </template>


                <div id="printer_" v-show="printer_show">
                    <div class="bod">
                        <h2 class="hh" >{{$t('table_header_1')}}</h2>
                        <el-table :data="detail.products" border style="width: 100%" >
                            <el-table-column prop="name" :label="$t('table_header_1')"  align="center" min-width="200px"/>
                            <el-table-column prop="product_no" :label="$t('table_header_2')"  align="center"/>
                            <el-table-column prop="count_name" :label="$t('table_header_3')"  align="center"/>
                            <el-table-column prop="product_date" :label="$t('table_header_4')"  align="center"/>
                            <el-table-column prop="count" :label="$t('table_header_5')"  align="center"/>
                            <el-table-column prop="unit_name" :label="$t('table_header_6')"  align="center"/>
                            <el-table-column prop="money" :label="$t('table_header_7')"  align="center"/>
                            <el-table-column prop="total_money" :label="$t('table_header_8')"  align="center"/>
                        </el-table>
                    </div>
                    <div class="bod h2">
                        <h2 class="hh" >{{$t('table_user_title')}}</h2>
                        <el-table :data="detail.headers0" border style="width: 100%">
                            <el-table-column  prop="x1" :label="$t('table_x1')" align="center" />
                            <el-table-column  prop="x2" :label="$t('table_x2')" align="center"  min-width="150px"/>
                            <el-table-column  prop="x3" :label="$t('table_x3')" align="center" min-width="150px" />
                            <el-table-column  prop="x4" :label="$t('table_x4')" align="center" />
                            <el-table-column  prop="x5" :label="$t('table_x5')" align="center" />
                            <el-table-column  prop="x6" :label="$t('table_x6')" align="center" />
                        </el-table>

                    </div>
                </div>

            </div>
            <div slot="footer" class="dialog-footer" style="text-align: center;" v-else>
                <div id="printer_" v-show="printer_show">
                    <div class="bod">
                        <h2 class="hh" >{{$t('table_header_1')}}</h2>
                        <el-table :data="detail.products" border style="width: 100%" >
                            <el-table-column prop="name" :label="$t('table_header_1')"  align="center" min-width="200px"/>
                            <el-table-column prop="product_no" :label="$t('table_header_2')"  align="center"/>
                            <el-table-column prop="count_name" :label="$t('table_header_3')"  align="center"/>
                            <el-table-column prop="product_date" :label="$t('table_header_4')"  align="center"/>
                            <el-table-column prop="count" :label="$t('table_header_5')"  align="center"/>
                            <el-table-column prop="unit_name" :label="$t('table_header_6')"  align="center"/>
                            <el-table-column prop="money" :label="$t('table_header_7')"  align="center"/>
                            <el-table-column prop="total_money" :label="$t('table_header_8')"  align="center"/>
                        </el-table>
                    </div>
                    <div class="bod h2">
                        <h2  class="hh">{{$t('table_user_title')}}</h2>
                        <el-table :data="detail.headers0" border style="width: 100%">
                            <el-table-column  prop="x1" :label="$t('table_x1')" align="center" />
                            <el-table-column  prop="x2" :label="$t('table_x2')" align="center"  min-width="150px"/>
                            <el-table-column  prop="x3" :label="$t('table_x3')" align="center" min-width="150px" />
                            <el-table-column  prop="x4" :label="$t('table_x4')" align="center" />
                            <el-table-column  prop="x5" :label="$t('table_x5')" align="center" />
                            <el-table-column  prop="x6" :label="$t('table_x6')" align="center" />
                        </el-table>

                    </div>
                </div>
                <el-button @click="handleClose" type="primary" style="margin-left: 2%;width: 15%">{{$t('close_submit')}}</el-button>
                <el-button @click="printer_click" type="primary" plain style="margin-left: 2%;width: 15%" :loading="submitLoading">{{$t('print_submit')}}</el-button>
            </div>

            <el-dialog
                    :title="$t('system_bohui')"
                    width="30%"
                    top="30vh"
                    :visible.sync="remonetDialog"
                    :before-close="remontClose"
                    :close-on-click-modal="false"
                    append-to-body
                    center>
                <el-form :model="remontFrom">
                    <el-form-item>
                        <el-input v-model="remontFrom.content" :placeholder="$t('content')"></el-input>
                    </el-form-item>
                </el-form>
                <div slot="footer" class="dialog-footer" style="text-align: center;">
                    <el-button @click="remontClose" style="margin-left: 2%;width: 30%">{{$t('cancel_button')}}</el-button>
                    <el-button type="primary" style="width: 30%" @click="remonetCreate(remontFrom)">{{$t('confrim_button')}}</el-button>
                </div>
            </el-dialog>

        </el-dialog>
        <el-dialog
                :title="$t('out_system_create')"
                :visible.sync="productCreateDialog"
                :width="$width"
                center
                :close-on-click-modal="false"
                :before-close="handleCloseProduct">

            <Row :gutter="10">
                <Col :span="10">
                    <Row :gutter="10">
                        <Col :span="12" style="float: right">
                            {{$t('customer_table.time')}}
                        </Col>
                        <Col :span="12" style="float: right">
                            <el-date-picker
                                    style="width: 100%"
                                    v-model="products.created_at"
                                    type="datetime"
                                    :placeholder="$t('customer_table.create_time')"
                                    :clearable="false"
                                    size="medium">
                            </el-date-picker>
                        </Col>
                        <Divider dashed />
                        <Col :span="12" style="float: right">
                            {{$t('customer_table.number_no')}}
                        </Col>
                        <Col :span="12" style="float: right">
                            <el-input size="medium" clearable v-model="products.order_num" :placeholder="$t('customer_table.create_number_no')"></el-input>
                        </Col>
                        <Divider dashed />
                        <Col :span="12" style="float: right">
                            {{$t('customer_table.out_type_name')}}
                        </Col>
                        <Col :span="12">
                            <el-select size="medium" v-model="products.out_type_id" :placeholder="$t('select')" style="width: 100%" :no-data-text="$t('empty')">
                                <el-option
                                        v-for="item in categorys"
                                        :key="item.id"
                                        :label="item.name"
                                        :value="item.id">
                                </el-option>
                            </el-select>
                        </Col>
                        <Divider dashed />
                        <Col :span="12" style="float: right">
                            {{$t('customer_table.to_warehouse_name')}}
                        </Col>
                        <Col :span="12">
                            <el-select size="medium" v-model="products.to_warehouse_id" :placeholder="$t('select')" style="width: 100%" :no-data-text="$t('empty')">
                                <el-option
                                        v-for="item in warehouseList"
                                        :key="item.id"
                                        :label="item.name"
                                        :value="item.id">
                                </el-option>
                            </el-select>
                        </Col>
                        <Divider dashed />
                        <Col :span="12" style="float: right">
                            {{$t('customer_table.transport_name')}}
                        </Col>
                        <Col :span="12">
                            <el-select size="medium" v-model="products.transport_id" :placeholder="$t('select')" style="width: 100%" :no-data-text="$t('empty')">
                                <el-option
                                        v-for="item in transportList"
                                        :key="item.id"
                                        :label="item.name"
                                        :value="item.id">
                                </el-option>
                            </el-select>
                        </Col>
                        <Divider dashed />
                        <Col :span="24">
                            <Row v-for="(item,index) in pre_payment_value" :key="index">
                                <Col :span="12" style="float: right">
                                    {{item.key}}
                                </Col>
                                <Col :span="12" style="float: right">
                                    <el-input-number style="width: 100%" size="medium" clearable v-model="item.price" :precision="2" :step="0.01" :min="0.00"></el-input-number>
                                </Col>
                                <Divider dashed />
                            </Row>
                        </Col>


                        <Col :span="24">
                            <upload-image :file-list="shop_image" delete-remove="deleteRemove" :type="true" :limit="$limit" action="customer/cms_up_image" @images="images"></upload-image>
                        </Col>
                    </Row>
                </Col>
                <Col :span="14">
                    <el-alert type="info" show-icon :title="$t('system')" :closable="false" :description="$t('out_system_product')"></el-alert>
                    <el-table
                            :data="tableDatas"
                            :header-cell-style="{'text-align':'center'}"
                            :cell-style="{'text-align':'center'}"
                            highlight-current-row
                            max-height="600"
                            ref="clickTable"
                            style="width: 100%"
                            v-loading="tableLoading"
                            size="mini"
                            @current-change="tableChange">
                        <el-table-column
                                prop="id"
                                type="index"
                                label="ID">
                        </el-table-column>
                        <el-table-column
                                prop="image"
                                :label="$t('customer_table.image')">
                            <template slot-scope="scope">
                                <el-image :src="$appUrl + scope.row.image"
                                          style="border-radius: 10px;width: 50px;height: 50px;cursor: pointer">
                                    <div slot="error" class="image-slot">
                                        <i class="el-icon-picture-outline"></i>
                                    </div>
                                </el-image>
                            </template>
                        </el-table-column>
                        <el-table-column
                                prop="name"
                                :label="$t('customer_table.name')">
                        </el-table-column>
                        <el-table-column
                                prop="product_no"
                                :label="$t('customer_table.product_no')">
                        </el-table-column>
                        <el-table-column
                                prop="count_name1"
                                :label="$t('customer_table.count')">
                        </el-table-column>
                    </el-table>
                    <div style="text-align: center;margin-top: 30px;">
                        <el-pagination
                                background
                                layout="prev, pager, next"
                                :total="totals"
                                :page-size="pagesizes"
                                @current-change="current_changes">
                        </el-pagination>
                    </div>
                </Col>
            </Row>

            <Spin fix v-if="showSpin"></Spin>
            <el-dialog
                    custom-class="dialogs"
                    :append-to-body="true"
                    :visible.sync="drawer"
                    :close-on-click-modal="false"
                    :before-close="outerVisible">
                <div slot="title" style="color: white;">
                    <Row>
                        <Col :span="11" v-show="$lang == 2" style="direction: ltr;font-size: 16px">
                            {{$t('system_out_product')}}
                        </Col>
                        <Col :span="13" :style="$lang == 1 ? {direction: 'rtl',fontSize: '16px'} :  {direction: 'ltr',fontSize: '16px'}">
                            {{detailsIn.name}}
                        </Col>
                        <Col :span="11" v-show="$lang == 1" style="direction: rtl;font-size: 16px">
                            {{$t('system_out_product')}}
                        </Col>
                    </Row>
                </div>
                <Row :gutter="10" :style="$lang == 2 ? {direction:'ltr',padding: '10px'} : {direction:'rtl',padding: '10px'}">
                    <Col :span="11">
                        <Row>

                            <Col :span="6">
                                <el-image :src="detailsIn.image_prefix"
                                          style="width: 100px;height: 100px;padding: 5px;">
                                    <div slot="error" class="image-slot">
                                        <i class="el-icon-picture-outline"></i>
                                    </div>
                                </el-image>
                            </Col>
                            <Col :span="18" style="font-size: 16px;line-height: 35px">
                                <div style="color: #5676ff">{{detailsIn.product_no}}</div>
                                <div>{{detailsIn.count_name}}</div>
                                <div style="color: #5676ff">
                                    {{detailsIn.product_date}}
                                </div>
                            </Col>
                            <!--                    <Col :span="10" :style="detailsIn.unit_type == 2 ? {fontSize: '16px',lineHeight:' 45px'} : {fontSize: '16px',lineHeight:' 35px'}">-->
                            <!--                       -->

                            <!--                    </Col>-->
                            <Divider dashed></Divider>
                            <Row :gutter="10" style="line-height: 50px">
                                <Col :span="12">
                                    {{detailsIn.unit_name}}
                                </Col>
                                <Col :span="12">
                                    <el-input-number v-model="productYu.count" :min="0" size="small"></el-input-number>
                                </Col>
                                <Col :span="12" v-show="detailsIn.unit_type == 1">
                                    {{detailsIn.child_unit_name}}
                                </Col>
                                <Col :span="12" v-show="detailsIn.unit_type == 1">
                                    <el-input-number v-model="productYu.child_count" :min="0" size="small"></el-input-number>
                                </Col>
                                <Col :span="12">
                                    {{$t('customer_table.price')}}
                                </Col>
                                <Col :span="12">
                                    <el-input-number v-model="productYu.price" :disabled="!is_edit_price" :precision="2" :step="0.01" :min="0.01" size="small"></el-input-number>
                                </Col>
                            </Row>

                        </Row>
                    </Col>

                    <Col :span="1">
                        <el-divider direction="vertical" class="dialog-hrs"></el-divider>
                    </Col>

                    <Col :span="12">
                        <div v-if="pre.list.length > 0">
                            <Row :gutter="2" class="products_aitio"  v-for="(item,index) in pre.list" :key="index">
                                <div @click="woClick(item)" style="width: 100%;display: flex">
                                    <Col :span="6">
                                        <el-image :src="item.image"
                                                  style="width: 80px;height: 80px;padding: 5px;">
                                            <div slot="error" class="image-slot">
                                                <i class="el-icon-picture-outline"></i>
                                            </div>
                                        </el-image>
                                    </Col>
                                    <Col :span="18" style="font-size: 15px;line-height: 20px">
                                        <div style="color: #5676ff">{{item.product_no}}</div>
                                        <div>{{$t('system_select')}}&nbsp;&nbsp;<span style="color: #5676ff">{{item.count}}</span></div>

                                        <Row>
                                            <Col :span="12" style="color: #5676ff">
                                                {{item.product_date}}
                                            </Col>
                                            <Col :span="12" style="color: red;direction: ltr;cursor: pointer">
                                                <i style="font-size: 20px" class="el-icon-delete-solid" @click="deleteFor(item.id)"></i>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col :span="12">
                                                {{$t('customer_table.total_money')}}
                                            </Col>
                                            <Col :span="12" style="color: red;direction: ltr">
                                                {{item.total_money}} X {{item.price}} = {{item.total_money * item.price | formatMoney}}
                                            </Col>
                                        </Row>
                                    </Col>
                                </div>
                            </Row>
                            {{$t('customer_table.total_money')}}:<span style="font-size: 16px;color: red">￥{{sum | formatMoney}}</span>
                        </div>
                        <div v-else>
                            <el-empty :image-size="90" :description="$t('charts_empty')"></el-empty>
                        </div>

                    </Col>

                </Row>
                <div slot="footer" style="text-align: center">
                    <el-button @click="outerVisible">{{$t('close_submit')}}</el-button>
                    <el-button type="primary" @click="childCreate(productYu)">{{$t('confrim_button')}}</el-button>
                </div>

            </el-dialog>
            <div slot="footer" style="direction: ltr">
                <el-button @click="handleCloseProduct">{{$t('cancel_button')}}</el-button>
                <el-button type="primary" @click="createFrom(products)">{{$t('in_mini_submit')}}</el-button>
            </div>
        </el-dialog>

    </div>
</template>

<script>
    import axios from 'axios'
    import UploadImage from "@/components/UploadImage";
    export default {
        name: "OutProduct",
        data() {
            return {
                printer_show:false,
                selectButtonType:1,   //默认按钮
                //外层部分，暂时使用，不完全追加
                productYu:{
                    id:'',
                    count:0,
                    child_count:0,
                    price:0,
                    product_date:'',
                },
                supplier_id:0,

                //


                direction: this.$lang == 1 ? 'rtl' : 'ltr',

                showSpin:true,
                canInGoods:0,

                drawer:false,

                categorys:[],
                warehouseList:[],
                transportList:[],

                tableDatas:[],

                detailsIn:'',

                shop_image:[],

                dialogVisible:false,
                itemsShowImage:[],
                remonetDialog:false,

                pre_payment_value:[],


                products:{
                    created_at:'',
                    order_num:'',
                    out_type_id:'',
                    to_warehouse_id:'',
                    transport_id:'',
                    images:[],
                    products:[],
                    payment_mode:[],
                    total_money:''
                },

                pre:{
                    list:[]
                },

                submitLoading:true,

                productCreateDialog:false,

                is_edit_price:'',

                detail:'',

                remontFrom:{
                    content:''
                },

                pickerOptions: {
                    shortcuts: [{
                        text: this.$i18n.t('quick.today'),
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24);
                            picker.$emit('pick', [start, end]);
                        }
                    },{
                        text: this.$i18n.t('quick.last_week'),
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                            picker.$emit('pick', [start, end]);
                        }
                    },{
                        text: this.$i18n.t('quick.last_month'),
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                            picker.$emit('pick', [start, end]);
                        }
                    },{
                        text: this.$i18n.t('quick.last_three_months'),
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                            picker.$emit('pick', [start, end]);
                        }
                    },{
                        text: this.$i18n.t('quick.last_six_months'),
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 180);
                            picker.$emit('pick', [start, end]);
                        }
                    }]
                },

                value2: '',

                tableLoading:true,

                tableData: [],

                total:0,
                pagesize:10,
                currentPage:1,

                totals:0,
                pagesizes:5,
                currentPages:1,

                spId:'',

                out_type:''
            }
        },
        components:{UploadImage},
        mounted() {
            this.userInfo()
            this.getServerData();
            if (window.performance.navigation.type == 1) {
                this.supplier_id = 1
            }else{
                this.supplier_id = 1
            }
        },
        computed:{
            sum(){
                return this.pre.list.reduce((sum,item)=>sum + (item.price * item.total_money),0)
            }
        },
        filters:{
            formatMoney(data){
                if (!data) return '0.00'
                // 将数据分割，保留两位小数
                data= data.toFixed(2)
                // 获取整数部分
                const intPart = Math.trunc(data)
                // 整数部分处理，增加,
                const intPartFormat = intPart.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')
                // 预定义小数部分
                let floatPart = '.00'
                // 将数据分割为小数部分和整数部分
                const newArr = data.toString().split('.')
                if (newArr.length === 2) { // 有小数部分
                    floatPart = newArr[1].toString() // 取得小数部分
                    return intPartFormat + '.' + floatPart
                }
                return intPartFormat + floatPart
            },
        },
        methods: {
            time(){
                let yy = new Date().getFullYear();
                let mm = new Date().getMonth()+1;
                let dd = new Date().getDate();
                let hh = new Date().getHours();
                let mf = new Date().getMinutes()<10 ? '0'+new Date().getMinutes() : new Date().getMinutes();
                let ss = new Date().getSeconds()<10 ? '0'+new Date().getSeconds() : new Date().getSeconds();
                let time = `${yy}-${mm}-${dd} ${hh}:${mf}:${ss}`


                return time;
            },
            order(){
                let date = new Date();
                let year = date.getFullYear();
                let month = date.getMonth() + 1;
                let day = date.getDate();
                let hour = date.getHours();
                let minute = date.getMinutes();
                let second = date.getSeconds();

                return (
                    year.toString() +
                    month.toString().padStart(2, '0') +
                    day.toString().padStart(2, '0') +
                    hour.toString().padStart(2, '0') +
                    minute.toString().padStart(2, '0') +
                    second.toString().padStart(2, '0') +
                    Math.random()
                        .toString()
                        .substr(2, 6)
                );
            },
            getServerData() {
                this.http()
            },
            userInfo(){
                var that = this
                axios.get(this.$baseUrl + 'user/info',{
                    headers:{
                        token:localStorage.getItem('token')
                    }
                })
                    .then((res)=>{
                        var datas = res.data.data.access
                        this.canInGoods = datas.can_out_goods
                        this.is_edit_price = datas.is_edit_price
                    })
            },
            current_change(page){
                if (this.supplier_id == 1){
                    this.$http.get('cmsOutReceipt/list',{
                        params:{
                            date:this.value2,
                            supplier_id:0,
                            page:page,
                            limit:10
                        }
                    }).then((res)=>{
                        this.tableData = res.data.items
                        this.total = res.count == undefined ? 0 : res.count

                    });
                }else{
                    this.selectButton(this.supplier_id,page)
                }
            },
            current_changes(page){
                this.currentPages = page;
                this.$http.get('cms_api/listCmsProductCms',{
                    params:{
                        keyword:'',
                        page:page,
                        limit:5
                    }
                }).then((res)=>{
                    this.tableDatas = res.data.items
                    this.totals = res.count == undefined ? 0 : res.count
                    this.showSpin = false
                })
            },
            dateTime(e){
                if (this.supplier_id == 1){
                    this.http(e);
                }else{
                    this.selectButton(this.supplier_id,1,e)
                }

            },
            http(e,state){
                let limit;
                if (state){
                    limit = 30
                }else if (state == 0){
                    limit = 30
                }else {
                    limit = 10
                }
                this.$http.get('cmsOutReceipt/list',{
                    params:{
                        date:e,
                        state:state,
                        supplier_id:0,
                        page:1,
                        limit:limit
                    }
                }).then((res)=>{
                    this.tableData = res.data.items
                    this.total = res.count == undefined ? 0 : res.count
                });

            },
            handleShow(row){
                var type = this.supplier_id
                this.dialogVisible = true
                this.spId = row.id
                if (type == 3){
                    this.$http.get('cms_api/listCmsProductTypeDetail',{
                        params:{
                            id:row.id
                        }
                    }).then((res)=>{
                        var images = res.data.images
                        let headers  = res.data.headers;
                        const array = [0,2,4,11,12,13];
                        let obj = {};
                        let f =1;
                        for(let i in headers){
                            for(let j in array){
                                if(i == array[j]){
                                obj['x'+f] = headers[i].value;f+=1;}
                            }
                        }
                        res.data.headers0 = [obj];
                        this.detail = res.data,
                        images.forEach((item,index)=>{
                            this.itemsShowImage.push(item.img)
                        })

                    })
                }else{
                    this.$http.get('outReceipt/detail',{
                        params:{
                            id:row.id
                        }
                    }).then((res)=>{
                        var images = res.data.images
                        let headers  = res.data.headers;
                        const array = [0,2,4,11,12,13];
                        let obj = {};
                        let f =1;
                        for(let i in headers){
                            for(let j in array){
                                if(i == array[j]){
                                obj['x'+f] = headers[i].value;f+=1;}
                            }
                        }
                        res.data.headers0 = [obj];
                        this.detail = res.data,

                        images.forEach((item,index)=>{
                            this.itemsShowImage.push(item.img)
                        })
                    })
                }
                this.showSpin = false
                this.submitLoading = false

            },
            handleClose(){
                this.dialogVisible = false
                this.itemsShowImage = []
                this.spId = ''
            },
            customerCreate(){
                this.$msConMessage.confirm(this.$i18n.t('system_success_confrim'),this.$i18n.t('system'),{
                    confirmButtonText:this.$i18n.t('confrim_button'),
                    cancelButtonText:this.$i18n.t('cancel_button'),
                    type:'warning',
                    closeOnClickModal:false
                }).then((res)=>{
                    console.log(this.out_type)
                    // this.$http.post('outReceipt/verify',{
                    //     id:this.spId,
                    //     out_type:this.out_type,
                    //     wx_order_id:this.out_type == 3 ? this.spId : 0
                    // }).then((res)=>{
                    //     this.homeShow()
                    // })
                }).catch(()=>{
                    return true;
                })
            },
            remont(){
                this.remonetDialog = true
            },
            printer_click(){
                //this.printer_show=!this.printer_show

                 // 获取 #printer_ 元素的内容
                let printerElement = document.querySelector('#printer_').innerHTML;
                // 创建一个新的窗口并写入打印内容
                const printWindow = window.open('print', '_blank');
                const header = document.querySelector("head").innerHTML;
                const style = document.querySelectorAll("style").innerHTML;
                
                
                printWindow.document.write(style);
                printWindow.document.write(header);
                printWindow.document.write(printerElement);
                
                printWindow.document.querySelectorAll('table').forEach(res => {
                    res.style.width = '99vw';
                });
                // 调用打印方法 
                
                setTimeout(() => {
                    printWindow.print(); 
                    printWindow.document.querySelector('body').style.display="none";
                    
                // 关闭打印窗口
                printWindow.close();
                },100);
            },
            remontClose(){
                this.remonetDialog = false
                this.remontFrom.content = ''
            },
            remonetCreate(from){
                if (from.content == ''){
                    this.$msMessage.error(this.$i18n.t('customer_table.create_reject'))
                }else{
                    this.$http.post('outReceipt/refuse',{
                        opinion:from.content,
                        id:this.spId
                    }).then((res)=>{
                        this.homeShow()
                    })
                }
            },
            homeShow(){
                this.remonetDialog = false
                this.dialogVisible = false
                this.http()
            },
            stateSreach(e){
                this.http([],e)
            },
            productCreate(){
                this.products.created_at = this.time()
                this.products.order_num  = this.order();
                this.productShow()
                this.productTableList()
                this.productCreateDialog = true
            },
            handleCloseProduct(){
                var from = this.products
                if (this.pre.list.length > 0){
                    this.$msConMessage.confirm(this.$i18n.t('system_success_in_product'),this.$i18n.t('system'),{
                        confirmButtonText:this.$i18n.t('confrim_button'),
                        cancelButtonText:this.$i18n.t('cancel_button'),
                        type:'warning',
                        closeOnClickModal:false
                    }).then((res)=>{
                        this.productCreateDialog = false
                        this.products.created_at = ''
                        this.products.order_num  = ''
                        this.products.total_money  = ''
                        this.products.images     = []
                        this.products.products     = []
                        this.products.transport_id = ''
                        this.products.out_type_id = ''
                        this.products.to_warehouse_id     = ''
                        this.shop_image = []
                        this.pre.list = []
                        this.products.payment_mode = []
                        this.pre_payment_value = []

                    }).catch(()=>{
                        return true;
                    })
                }else{
                    this.productCreateDialog = false
                    this.products.created_at = ''
                    this.products.order_num  = ''
                    this.products.total_money  = ''
                    this.products.images     = []
                    this.products.products     = []
                    this.products.transport_id = ''
                    this.products.out_type_id = ''
                    this.products.to_warehouse_id     = ''
                    this.shop_image = []
                    this.pre.list = []
                    this.products.payment_mode = []
                    this.pre_payment_value = []
                }
            },
            productShow(){
                this.$http.get('cmsOutReceipt/outWarehouseList')
                    .then((res)=>{
                        this.categorys = res.data.inOutList
                        this.warehouseList = res.data.customerList
                        this.transportList = res.data.transportList

                        if (this.supplier_id !== 3 && this.supplier_id !== 2){
                            res.data.payment_mode.forEach((item,index)=>{
                                this.pre_payment_value.push({
                                    key:item.key,
                                    value:item.value,
                                    price:''
                                })
                            })
                        }

                        this.showSpin = false
                    })
            },
            productTableList(){
                this.$http.get('cms_api/listCmsProductCms',{
                    params:{
                        keyword:'',
                        page:1,
                        limit:5
                    }
                }).then((res)=>{
                    this.tableDatas = res.data.items
                    this.totals = res.count == undefined ? 0 : res.count
                    this.showSpin = false
                })
            },
            images(res){
                var images = res
                this.products.images.push(images.image_url)

            },
            tableChange(row){
                if (row == undefined){

                }else{
                    if (this.supplier_id !== 3){
                        if (row.count <= 0){
                            this.$msMessage.error(this.$i18n.t('xiao_one'))
                            return false;
                        }
                        this.detailsIn = row
                        this.productYu.price = row.price
                        this.productYu.id = row.id
                        this.productYu.product_date = row.product_date
                        this.drawer = true
                    }

                }
            },
            outerVisible(){
                this.productYu.price = 0
                this.productYu.count = 0
                this.productYu.child_count = 0
                this.productYu.product_date = 0
                this.drawer = false
            },
            childCreate(from){
                if (this.detailsIn.unit_type == 2){
                    if (from.count == '' || from.count == undefined || from.count == 0){
                        this.$msMessage.error(this.$i18n.t('system_empty_count_out'))
                        return false;
                    }
                }else{
                    if (from.count == '' || from.count == undefined || from.count == 0){
                        this.$msMessage.error(this.$i18n.t('system_empty_count_out'))
                        return false;
                    }else if (from.child_count == '' || from.child_count == undefined || from.child_count == 0){
                        this.$msMessage.error(this.$i18n.t('system_empty_count_out'))
                        return false;
                    }
                }
                var validate = this.oneX(from)
                if (validate) {

                    var count = this.counts(from)
                    var index = this.pre.list.some(item=>{  //判断
                        return item.id === from.id
                    })

                    if (index){
                        this.pre.list = this.pre.list.map((item)=>{
                            if (item.id === from.id){
                                return {
                                    ...item,
                                    count:count,
                                    counts:this.productYu.count,
                                    price:this.productYu.price,
                                    child_count:this.productYu.child_count,
                                    total_money:this.detailsIn.unit_type == 1 ? (this.detailsIn.case_in_num * this.productYu.count) + this.productYu.child_count : this.productYu.count
                                }
                            }
                            return item;
                        })
                    }else{
                        this.pre.list.push({
                            id:this.productYu.id,
                            name:this.detailsIn.name,
                            unit_type:this.detailsIn.unit_type,
                            count:count,
                            count_name:this.detailsIn.count_name,
                            child_unit_name:this.detailsIn.child_unit_name,
                            unit_name:this.detailsIn.unit_name,
                            counts:this.productYu.count,
                            image:this.detailsIn.image_prefix,
                            product_no:this.detailsIn.product_no,
                            created_at:this.detailsIn.created_at,
                            product_date:this.detailsIn.product_date,
                            child_count:this.productYu.child_count,
                            price:this.productYu.price,
                            total_money:this.detailsIn.unit_type == 1 ? (this.detailsIn.case_in_num * this.productYu.count) + this.productYu.child_count : this.productYu.count
                        })
                    }

                }
            },
            oneX(from){
                if (this.detailsIn.unit_type == 2){
                    if (from.count > this.detailsIn.count){
                        this.$msMessage.error(this.$i18n.t('xiao_one'))
                        return false;
                    }else{
                        return true;
                    }
                }else{
                    var count = this.countsDa(this.detailsIn.case_in_num,from.count,from.child_count)
                    if (count > this.detailsIn.count){
                        this.$msMessage.error(this.$i18n.t('xiao_one'))
                        return false;
                    }else{
                        return true;
                    }
                }
            },
            countsDa(caseInNum,count,childCount){
                return caseInNum * count + childCount
            },
            deleteFor(id){
                this.pre.list = this.pre.list.filter((e)=>{
                    return e.id !== id
                })
            },
            counts(from){
                var count;
                if (this.detailsIn.unit_type == 2){
                    // count = (this.productYu.count * this.detailsIn.case_in_num) + this.detailsIn.child_unit_name
                    count = this.productYu.count + this.detailsIn.child_unit_name
                }else{
                    // count = (this.productYu.count * this.detailsIn.case_in_num) + from.child_count
                    count = this.productYu.count + this.detailsIn.unit_name + this.productYu.child_count + this.detailsIn.child_unit_name
                }
                return count;
            },
            woClick(e){
                this.productYu.count = e.counts
                this.productYu.price = e.price
                this.productYu.child_count = e.child_count
                this.productYu.id = e.id
                this.detailsIn.product_no = e.product_no;
                this.detailsIn.name = e.name;
                this.detailsIn.image_prefix = e.image;
                this.detailsIn.product_date = e.product_date;
                this.detailsIn.unit_type = e.unit_type;
                this.detailsIn.count_name = e.count_name
                this.detailsIn.unit_name = e.unit_name
                this.detailsIn.child_unit_name = e.child_unit_name
            },
            createFrom(from){
                var is = this.validate(from)
                var products = this.products;
                if (is){
                    if (this.supplier_id !== 3){
                        this.productsList(this.pre.list)
                    }
                    this.$http.post('outReceipt/create',{
                        created_at:products.created_at,
                        out_type_id:products.out_type_id,
                        transport_id:products.transport_id,
                        to_warehouse_id:products.to_warehouse_id,
                        order_num:products.order_num,
                        images:products.images,
                        products:products.products,
                        total_money:products.total_money,
                        payment_mode:products.payment_mode,
                        out_type:this.out_type
                    }).then((res)=>{
                        this.cloceQRplat()
                    })
                }

            },
            cloceQRplat(){
                this.productCreateDialog = false
                this.products.created_at = ''
                this.products.order_num  = ''
                this.products.total_money  = ''
                this.products.images     = []
                this.products.products     = []
                this.products.out_type_id = ''
                this.products.transport_id = ''
                this.products.payment_mode = []
                if (this.out_type == 3) this.dialogVisible = false;
                this.pre_payment_value = []
                this.products.to_warehouse_id     = ''
                this.shop_image = []
                this.pre.list = []
                if (this.supplier_id == 3){
                    this.productCreateDialog = false
                }
                this.http()

            },
            productsList(array){
                var data = this.pre.list
                var sum = 0;
                array.forEach((item,index)=>{
                    this.products.products.push({
                        id:item.id,
                        count:item.total_money,
                        price:item.price,
                        product_date:item.product_date !== '0000-00-00' ? item.product_date : item.created_date
                    })
                    sum += item.price * item.total_money
                })
                this.products.total_money = this.formatMoneyTotal(sum)
            },
            validate(from){
                var paymentMode = this.pre_payment_value
                paymentMode.forEach((item,index)=>{
                    if (item.price){
                        var indexs = this.products.payment_mode.some((items,index)=>{
                            return item.value === items.value
                        })
                        if (indexs){
                            this.products.payment_mode = this.products.payment_mode.map((iitem,value)=>{
                                if (item.value === iitem.value){
                                    return {
                                        ...iitem,
                                        key:item.key,
                                        value:item.value,
                                        price:item.price
                                    }
                                }
                                return iitem
                            })
                        }else{
                            this.products.payment_mode.push({
                                key:item.key,
                                value:item.value,
                                price:item.price
                            })
                        }
                    }
                })
                if (from.created_at == ''){
                    this.$msMessage.error(this.$i18n.t('system_require'))
                    return false;
                }else if (from.order_num == ''){
                    this.$msMessage.error(this.$i18n.t('system_require'))
                    return false;
                }else if (from.out_type_id == ''){
                    this.$msMessage.error(this.$i18n.t('system_require'))
                    return false;
                }else if (from.to_warehouse_id == ''){
                    this.$msMessage.error(this.$i18n.t('system_require'))
                    return false;
                }else if (from.transport_id == ''){
                    this.$msMessage.error(this.$i18n.t('system_require'))
                    return false;
                }else if (from.images.length <= 0){
                    this.$msMessage.error(this.$i18n.t('customer_table.create_image'))
                    return false;
                }else if (this.pre.list.length <= 0){
                    this.$msMessage.error(this.$i18n.t('system__out_require'))
                    return false;
                }else{
                    return true;
                }
            },
            validates(from){

            },
            formatMoneyTotal(data){
                if (!data) return '0.00'
                // 将数据分割，保留两位小数
                data= data.toFixed(2)
                // 获取整数部分
                const intPart = Math.trunc(data)
                // 整数部分处理，增加,
                const intPartFormat = intPart.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1')
                // 预定义小数部分
                let floatPart = '.00'
                // 将数据分割为小数部分和整数部分
                const newArr = data.toString().split('.')
                if (newArr.length === 2) { // 有小数部分
                    floatPart = newArr[1].toString() // 取得小数部分
                    return intPartFormat + '.' + floatPart
                }
                return intPartFormat + floatPart
            },
            //下单分类
            selectButton(type,page,date){
                if (page == undefined){
                    page = 1
                }
                this.supplier_id = type
                this.out_type = type
               if (type == 1){
                   this.http()
               }else{
                   this.$http.get('cms_api/listCmsProductTypeList',{
                       params:{
                           type:type,
                           supplier_id:type,
                           page:page,
                           limit:10
                       }
                   }).then((res)=>{
                       this.tableData = res.data.items
                       this.total = res.count == undefined ? 0 : res.count
                   })
               }
            },
            miniCreate(){
                this.tableData.forEach((item,index)=>{
                    this.tableDatas = item.products
                })
                this.products.total_money = this.tableDatas.reduce((sum,item)=>sum + (item.price * item.count),0)
                this.productCreateDialog = true
                this.productShow()
                this.products.created_at = this.time()
                this.products.order_num  = this.order();
                this.tableDatas.forEach((item,index)=>{
                    this.products.products.push({
                        id:item.id,
                        count:item.count,
                        price:item.price,
                        product_date:item.product_date
                    })
                    this.pre.list.push({
                        id:item.id,
                        count:item.count,
                        price:item.price,
                        product_date:item.product_date
                    })
                })
            },

        },
    }
</script>

<style scoped>
    /deep/.el-dialog{
        border-radius: 10px;
    }
    .charts-box {
        width: 100%;
        height: 600px;
    }
    /deep/.ivu-divider{
        margin: 10px 0;
        border-top: 1px dashed #c5c5c9;
    }
    .dialog-hr{
        width: 5%;
        height: 100%;
        background-color:#e8eaec;;
        border-radius: 2%;
        border:1px solid #e8eaec;
    }
    .dialog-hrs{
        width: 5%;
        height: 100%;
        background-color:#e8eaec;
        border:1px solid #e8eaec;
        border-radius: 12px;
    }
    .products_detail:hover{
        background-color: #f4f4f4;
        border-radius: 12px;
    }
    /deep/.dialogs .el-dialog__header{
        background-color: #00aeff;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
    }
    /deep/.dialogs .el-dialog__headerbtn .el-dialog__close{
        color: #ffffff;
    }
    .products_aitio{
        width: 100%;
        border: 2px dashed #e8eaec;
        border-radius: 10px;
        padding: 2px;
        margin: 2px;
        cursor: pointer;
    }
    .products_aitio:hover{
        background-color: #f4f4f4;
        cursor: pointer;
    }


    .bod{
        margin: 10px 0;
    }
    .hh{
        margin:30px 0;
        text-align: center;
        color:rgb(105, 105, 105);
    }
    .h2{
        border-top:1px dashed rgb(60, 60, 60);
    }
    

    #printer_{
        widows: 100vw;
        margin: 0;
        padding: 0;
    }
    .el-table__body-wrapper table{
            margin:50px 0px;
            width: 99vw !important;
        }
</style>